type CacheData = {
  data: any
  expiration?: number
}

type Listener = Record<string, (data?: any) => void>

type CacheAddListenerProps = {
  id: string
  event: string
  handler: (data?: any) => void
}

type StorageAddListenerProps = CacheAddListenerProps & {
  key: string
}

class Cache {
  private data: any
  private expiration: number
  private listeners: Record<string, Listener> = {}

  constructor(props: CacheData | CacheAddListenerProps) {
    const { data, expiration } = props as CacheData

    if (data && expiration) {
      this.set({ data, expiration })
    }
    else {
      const { id, event, handler } = props as CacheAddListenerProps

      this.addListener({ id, event, handler })
    }
  }

  private checkIsExpired(expiration: number) {
    return Date.now() > expiration
  }

  get() {
    const isExpired = this.checkIsExpired(this.expiration)

    if (isExpired) {
      this.data = null
    }

    return this.data
  }

  // We don't check expiration on set, to update data in listeners
  set({ data, expiration = Infinity }: CacheData) {
    this.data = data || null
    this.expiration = expiration

    Object.keys(this.listeners).forEach((key) => {
      this.listeners[key]?.set?.(this.data)
    })

    return this.data
  }

  // TODO check expiration
  setIn(setFunction: (data: any) => any) {
    this.data = setFunction(this.data) || null

    Object.keys(this.listeners).forEach((key) => {
      this.listeners[key]?.set?.(this.data)
    })

    return this.data
  }

  reset() {
    this.data = null
    this.expiration = 0

    Object.keys(this.listeners).forEach((key) => {
      this.listeners[key]?.reset?.()
    })
  }

  addListener({ id, event, handler }: CacheAddListenerProps) {
    this.listeners[id] = this.listeners[id] || {}

    this.listeners[id][event] = handler
  }

  removeListener({ id, event }: { id: string, event: string }) {
    if (this.listeners[id]) {
      this.listeners = {
        ...this.listeners,
        [id]: {
          ...this.listeners[id],
          [event]: null,
        },
      }
    }
  }

  removeAllListeners(id: string) {
    this.listeners = {
      ...this.listeners,
      [id]: null,
    }
  }
}

export class CacheStorage {
  private storage: Record<string, any> = {}

  get(key: string) {
    if (this.storage[key]) {
      return this.storage[key].get()
    }

    return null
  }

  set(key: string, cacheData: CacheData) {
    const cache = this.storage[key]

    if (cache) {
      cache.set(cacheData)
    }
    else {
      this.storage[key] = new Cache(cacheData)
    }
  }

  setIn(key: string, setFunction: (data: any) => any) {
    const cache = this.storage[key]

    if (cache) {
      cache.setIn(setFunction)
    }
    else {
      throw new Error(`Cache not exist: ${key}, can't handle 'setIn' action`)
    }
  }

  reset(key: string | typeof RegExp) {
    const isRegExp = typeof key === 'object'

    if (isRegExp) {
      const cacheItems = Object.keys(this.storage).filter((storageKey) => key.test?.(storageKey))

      cacheItems.forEach((storageKey) => {
        this.reset(storageKey)
      })
    }
    else {
      const cache = this.storage[key]

      if (cache) {
        cache.reset()
      }
    }
  }

  resetAll() {
    Object.keys(this.storage).forEach((key) => {
      this.reset(key)
    })
  }

  addListener({ id, key, event, handler }: StorageAddListenerProps) {
    const cache = this.storage[key]

    if (!cache) {
      if (event !== 'reset') {
        throw new Error(`Cache not exist: ${key}, can't subscribe on 'set' event`)
      }

      this.storage[key] = new Cache({ id, event, handler })
    }
    else {
      cache.addListener({ id, event, handler })
    }
  }

  removeListener({ key, id, event }: { key: string, id: string, event: string }) {
    const cache = this.storage[key]

    if (!cache) {
      cache.removeListener({ id, event })
    }
  }

  removeAllListeners({ key, id }: { key: string, id: string }) {
    const cache = this.storage[key]

    if (cache) {
      cache.removeAllListeners(id)
    }
  }
}

const cacheStorage = __CLIENT__ ? window.cacheStorage || new CacheStorage() : new CacheStorage()

if (__CLIENT__) {
  if (!window.cacheStorage) {
    window.cacheStorage = cacheStorage
  }
}

// if (__CLIENT__) {
//   Object.keys(window.__QUERY_STATE__) {
//   }
// }

// if (__DEV__ && __CLIENT__) {
//   window.cacheStorage = cacheStorage
// }

export default cacheStorage
